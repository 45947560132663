<template>
  <div class="container container-form">
    <h3>DATOS DEL PEDIDO</h3>

    <!-- Form -->
    <form class="form-horizontal col-md-6 col-xs-12" @submit.prevent="nextStep">
      <div class="form-container">
        <!-- DATOS DE CONTACTO -->
        <div class="form-group" v-if="step === 1">
          <h4>Datos de Contacto</h4>
          <div class="form-scroll-area">
            <input
              class="form-control col-sm-12 col-xs-12 margin-b"
              v-model="formValues.client.email"
              type="email"
              name="email"
              placeholder="Email"
              required
            />

            <input
            class="form-control col-sm-12 col-xs-12 margin-b"
            v-model="formValues.client.name"
            type="text"
            name="nombre"
            placeholder="Nombre completo"
            required
            />

            <input
            class="form-control col-sm-12 col-xs-12 margin-b"
            v-model="formValues.client.phone"
            type="tel"
            name="teléfono"
            placeholder="Teléfono"
            required
            />

            <input
            class="form-control col-sm-12 col-xs-12 margin-b"
            v-model="formValues.client.mobphone"
            type="tel"
            name="teléfono"
            placeholder="Teléfono móvil"
            />

            <input
            class="form-control col-sm-12 col-xs-12 margin-b"
            v-model="formValues.client.address"
            type="text"
            name="dirección"
            placeholder="Dirección"
            required
            />

            <input
            class="form-control col-sm-12 col-xs-12"
            v-model="formValues.client.city"
            type="text"
            name="localidad"
            placeholder="Localidad"
            required
            />
          </div>
        </div>

        <!-- DATOS DE ENVIO -->
        <div class="form-group" v-if="step === 2">
            <h4>Datos de Envio</h4>
            <div class="form-scroll-area">
              <div class="radio-container">
                <div class="radio-inline">
                <label>
                    <input
                    type="radio"
                    id="Entrega a domicilio"
                    :value="false"
                    v-model="formValues.retireOnSite"
                    checked
                    />
                    Envio a domicilio
                </label>
                </div>
                <div class="radio-inline">
                <label>
                    <input
                    type="radio"
                    id="Retira del local"
                    :value="true"
                    v-model="formValues.retireOnSite"
                    />
                    Retiro en el local
                </label>
                </div>
            </div>
            <input
              class="form-control col-sm-12 col-xs-12 margin-b"
              v-model="formValues.shipping.address"
              type="text"
              name="calle"
              placeholder="Calle"
              :disabled="formValues.retireOnSite === true"
              required
            />

            <div class="col-md-6 col-xs-6 col-num margin-b">
              <input
              class="form-control col-md-12 col-xs-12"
              v-model="formValues.shipping.postalcode"
              type="text"
              name="numero"
              placeholder="Número"
              :disabled="formValues.retireOnSite === true"
              required
              />
            </div>
            <div class="col-md-5 col-xs-5 col-dpto col-md-offset-1 col-xs-offset-1">
              <input
                class="form-control col-md-12 col-xs-12"
                v-model="formValues.shipping.floor"
                type="text"
                name="departamento"
                placeholder="Depto"
                :disabled="formValues.retireOnSite === true"
              />
            </div>
            <input
              class="form-control col-sm-12 col-xs-12 margin-b"
              v-model="formValues.shipping.district"
              type="text"
              name="barrio"
              placeholder="Barrio"
              :disabled="formValues.retireOnSite === true"
              required
            />
            <input
              class="form-control col-sm-12 col-xs-12 margin-b"
              v-model="formValues.shipping.directive"
              type="text"
              name="calles"
              placeholder="Entre calles:"
              :disabled="formValues.retireOnSite === true"
              required
            />
            <div class="form-group"></div>
            <div class="checkbox" style="margin-top: -20px">
                <label>
                <input
                    type="checkbox"
                    id="otraPersona"
                    v-model="formValues.retireByThird"
                    :disabled="formValues.retireOnSite === false"
                />
                Retira otra Persona
                </label>
            </div>
            <input
              class="form-control col-sm-12 col-xs-12 margin-b"
              v-model="formValues.retire.name"
              type="text"
              name="nombreTercero"
              placeholder="Nombre"
              :disabled="!formValues.retireByThird"
              required
            />
            <input
              class="form-control col-sm-12 col-xs-12"
              v-model="formValues.retire.dni"
              type="text"
              name="dniTercero"
              placeholder="DNI"
              :disabled="!formValues.retireByThird"
              required
            />
            
          </div>
        </div>

        <!-- DATOS DE FACTURACION -->
        <div class="form-group" v-if="step === 3">
            <h4>Datos de facturación</h4>
            <div class="form-scroll-area">
            <div class="radio-container">
            <div class="radio-inline">
                <label>
                <input
                    type="radio"
                    id="consumidor final"
                    :value="true"
                    v-model="formValues.finalConsumer"
                    checked
                />
                Soy consumidor final
                </label>
            </div>
            <div class="radio-inline">
                <label>
                <input
                    type="radio"
                    id="necesito factura"
                    :value="false"
                    v-model="formValues.finalConsumer"
                />
                Necesito factura
                </label>
            </div>
            </div>
            <label for="razonSocial" class="col-sm-12 col-xs-12"
            >Razón social o Nombre :</label
            >
            <input
            class="form-control col-sm-12 col-xs-12"
            v-model="formValues.billing.name"
            type="text"
            name="razonSocial"
            :disabled="formValues.finalConsumer === true"
            required
            />
            <label for="cuitCuilDni" class="col-sm-12 col-xs-12">CUIT / CUIL / DNI :</label>
            <input
            class="form-control col-sm-12 col-xs-12"
            v-model="formValues.billing.cuit"
            type="text"
            name="cuitCuilDni"
            :disabled="formValues.finalConsumer === true"
            required
            />

            <label for="tipoIva" class="col-sm-12 col-xs-12">Tipo de IVA :</label>
            <select
            v-model="formValues.billing.type"
            name="tipoIva"
            class="form-control input-lg"
            :disabled="formValues.finalConsumer === true"
            required
            >
            <option disabled value="">Seleccione una opción</option>
            <option value="mon">Monotributo</option>
            <option value="ins">Inscripto</option>
            <option value="exe">Exento</option>
            </select>
          </div>
        </div>

        <!-- FORMA DE PAGO -->
        <div class="form-group" v-if="step === 4">
          <h4>Forma de pago</h4>
          <br>
          <h4 class="button-title">Seleccionar</h4>
          <div class="metodo-pago-container" v-if="configuration.show_mercadopago">
            <!-- <a id="promo"
              :href="(mp_url !== undefined && mp_url.data !== undefined)? mp_url.data.url : ''"
              mp-mode="modal"
              name="MP-Checkout"
              class="forma-pago-btn btn btn-lg"
              v-if="configuration.show_mercadopago"
              :class="[selectedOption === 'mercadopago' ? 'activeClass' : '']"
              target="_blank">
              PAGAR CON MERCADOPAGO
              <img src="https://http2.mlstatic.com/frontend-assets/ui-navigation/5.14.3/mercadopago/logo__large.png"
                  alt="Mercado pago logo"
                  class="forma-pago-img" />
            </a> -->
            <button type="button" class="forma-pago-btn btn-lg mercado-pago" :class="[selectedOption === 'mercadopago' ? 'activeClass' : '']" @click="selectOption('mercadopago')">
              PAGAR CON MERCADOPAGO
              <img src="https://http2.mlstatic.com/frontend-assets/ui-navigation/5.14.3/mercadopago/logo__large.png" alt="Mercado pago logo" class="forma-pago-img" />
            </button>
            <label for="mercadopago" @click="getSelectedOption"><span class="input-radio"><span class="input-radio__body"><input v-model="selectedOption" id="mercadopago" value="mercadopago" class="input-radio__input" type="radio"> <span class="input-radio__circle"></span></span></span></label>
          </div>

          <div class="metodo-pago-container">
            <button  type="button" class="forma-pago-btn btn-lg pago-efectivo" :class="[selectedOption == 'efectivo' ? 'activeClass' : '']" @click="selectOption('efectivo')">
              A CONVENIR<i class="fal fa-money-bill-alt forma-pago-icon"></i>
            </button>
            <label for="pagoefectivo" @click="getSelectedOption"><span class="input-radio"><span class="input-radio__body"><input v-model="selectedOption" id="pagoefectivo" value="efectivo" class="input-radio__input" type="radio" checked="checked"> <span class="input-radio__circle"></span></span></span></label>
          </div>

          <div class="textarea-container">
            <label for="notasDePedido" class="col-sm-12">Notas de pedido :</label>
            <textarea name="notasDePedido" class="form-control" v-model="formValues.notes" rows="3"></textarea>
          </div>
        </div>
      </div>
      <!-- BOTONES DE NAVEGACION -->
      <div class="btn-container fix">
        <button type="button" @click="prevStep" class="btn btn-default btn-lg " :disabled="step===1">
          Atras
        </button>
        <button v-if="step < 4" type="submit" class="btn btn-default btn-lg" >
          Continuar
        </button>
        <button v-if="step == 4" type="button" class="btn btn-default btn-lg" @click="setFormValues" >
          Finalizar
        </button>
      </div>
    </form>

    <!-- Products list -->
    <div class="col-md-6 col-xs-12">
      <div class="products-list-container">
        <h4>Listado de productos</h4>
        <div class="checkOut-basket-products">
          <ul>
            <li
                  v-for="item in basket"
                  :key="item.product.id +'-'+ item.variant.id"
                  class="product-cart mini_cart_item center-block"
                >
                  <MiniCartItem :item="item" :withaddbuttons="false" />
                </li>
          </ul>
          <div class="checkOut-cart-subtotal">
            <span>
              Total:
              <span>${{ basketSum }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="orderModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
    >
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-body text-center">
            <div class="alert alert-success text-center" role="alert">
              Tu pedido ha sido enviado
            </div>
            <button type="button" class="btn btn-default" data-dismiss="modal">
              <router-link class="modal-router-link" :to="{ name: 'Home'}">
                Cerrar
              </router-link>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript" src="https://www.mercadopago.com/org-img/jsapi/mptools/buttons/render.js"></script>
<script>
import MiniCartItem from "../components/MiniCartItem";
import { mapGetters, mapState, mapActions } from "vuex";


export default {
  name: "Checkout-Flow",

  components: { MiniCartItem },

  data() {
    return {
      selectedOption: "efectivo",
      step: 1,
      totalSteps: 4,
      formValues: {
        //Step 1
        client:{
            email: '',
            name: '',
            phone: '',
            mobphone:'',
            address:'',
            city: '',
            dni:''
        },
        //Step 2
        retireOnSite:true,
        shipping: {
            address : '',
            postalcode:'',
            floor : '',
            district:'',
            directive:'',
        },
        retireByThird:false,
        retire:{
            name:'',
            dni:'',
        },
        //Step 3
        finalConsumer:true,
        billing:{
            name:'',
            cuit:'',
            type:'',
        },
        //Step 4
        paymethod:{
            description:'efectivo',
            token:'',
        },
        notes:''
      },
    }
  },
  mounted() {
    if(this.user!=null){
      this.formValues.client.email = this.user.email
      this.formValues.client.phone = (this.user.phone!==null) ? this.user.phone : ''
    }
    if(this.client!=null){
      this.formValues.client.name = this.client.name
      if(this.user.phone==null){
        this.formValues.client.phone = this.client.phone_1
      }
      this.formValues.client.address = this.client.address
      this.formValues.client.city = this.client.city
      this.formValues.client.dni = this.client.dni

      this.formValues.shipping.address = this.client.address
      this.formValues.shipping.postalcode = this.client.postalcode

      this.formValues.billing.name = (this.client.business!==null) ? this.client.business : this.client.name
      this.formValues.billing.cuit = this.client.cuit

      if(this.client.iva_type !='Consumidor Final'){
        this.formValues.finalConsumer = false
        switch(this.client.iva_type){
          case 'Monotributo':
            this.formValues.billing.type = 'mon'
            break
          case 'Excento':
            this.formValues.billing.type = 'exc'
            break
          case 'Responsable Inscripto':
            this.formValues.billing.type = 'ins'
            break
        }
      }
    }
  },
  computed: {
    ...mapState('ecommerce', ["basket"]),
    ...mapGetters({
      basketCount: "ecommerce/basketCount", 
      basketSum: "ecommerce/basketSum", 
      order: "ecommerce/order",
      client: "ecommerce/client",
      user: "ecommerce/user",
      configuration: "ecommerce/configuration", 
      mp_url: "ecommerce/mp_url"
    })
  },
  watch: {
    order(newOrder){
      if( newOrder !== undefined && newOrder.id !== undefined ){
        this.$router.push({ name: "CompleteOrder", params: { company: this.$route.params.company } })
      } else {
        alert('Error al crear la orden: ' + newOrder)
      }
    },
    mp_url(newurl){
      this.formValues.paymethod.token = newurl.data.external_reference
    }
  },
  methods: {
    ...mapActions({
      createOrderEcommerce: 'ecommerce/createOrderEcommerce',
      getMercadoPagoUrl: 'ecommerce/getMercadoPagoUrl'
    }),
    nextStep() {
      if (this.basketCount === 0) {
        this.$router.push({ name: "Home", params: { company: this.$route.params.company } })
        return
      }
      if(this.configuration.show_mercadopago == true){
        this.getMercadoPagoUrl({
          values: this.formValues,
          items: this.basket
        });
      }
      this.step++;
    },
    prevStep() {
      return this.step--;
    },
    setPaymethod(e){
      this.formValues.paymethod.description = e;
    },
    setFormValues(){
      if (this.basketCount === 0) {
        this.$router.push({ name: "Home", params: { company: this.$route.params.company } })
        return
      }
      this.createOrderEcommerce({
        values: this.formValues,
        items: this.basket,
        totals:{
          subtotal:this.basketSum
        }
      });
    },
    getSelectedOption() {
      this.formValues.paymethod.description = this.selectedOption
    },
    selectOption(option) {
      this.selectedOption = option;
    }
  },
}
</script>

<style scoped>

.form-container{
    overflow-y: auto;
    padding-right: 0;
    box-sizing: border-box;
}
/* Form styles */
.modal-router-link{
  color: inherit;
}
.form-horizontal{
  height: 600px;
  position: relative;
}
label {
  margin-top: 1em;
  padding-left: 0 !important;
}
.form-horizontal .form-group {
  margin-right: 0;
  margin-left: 0;
}
.form-group .form-scroll-area {
  padding-right: 20px;
  box-sizing: border-box;
}
.btn-default {
  margin-right: 1em;
  margin-top: 1em;
  margin-bottom: 3em;
  width: 110px;
}
.col-num,
.col-dpto {
  padding: 0;
}
.checkbox {
  padding-left: 20px;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #e3e3e3;
}
select.input-lg {
  height: 42px;
  line-height: 42px;
}
.input-lg {
  height: 42px;
  font-size: 16px;
  border-radius: 4px;
}
.form-control:focus {
  border-color: #66afe9;
  color: #868686;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.forma-pago-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: lightgray !important;
}
.activeClass {
  background-color: var(--accent-color)!important;
}
.mercado-pago.activeClass {
  background-color: #65cfff!important;
}
.btn-container {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
}

@media ( max-width:460px){
    .forma-pago-btn{
    flex-direction: column;
  }
/*
  .radio-container{
    display: flex;
    flex-direction: column;
  }
*/
  .radio-inline {
    margin-right: 5px;
  }
  .radio-inline+.radio-inline {
    margin-top:0;
    margin-left:0px;
  }
  .btn-container {
    display: flex;
    justify-content: space-between;
    position: static;
  }
  .btn-default[data-v-11469264] {
    margin-right: 0;
    width: 45%;
  }
  .form-horizontal{
    height: auto;
    position: static;
  }

}
.textarea-container {
  margin-top: 3em;
}
.button-title {
  color: #868686;
  font-weight: 500;
  font-size: 16px;
}
.forma-pago-img {
  width: auto;
  height: auto;
}
.forma-pago-icon {
  font-size: 3.75rem;
  margin-right: 0.25em;
}
.modal-button:active {
  background-color: #2d9b9b;
}
h3 {
  margin: 1em 0;
}
.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none;
    outline-offset: none;
}
/* List products styles */
.products-list-container{
  margin-left: 1em;
  height: calc(100vh - 40px);
  position: relative;
}

.products-list-container > h4 {
  text-align: center;
}
.checkOut-cart-subtotal{
  font-size: 20px;
  color: black;
  text-align: center;
  position: absolute;
  bottom: 50px;
  left: 150px;
}
.checkOut-basket-products{
 padding: 2em;
}
.checkOut-basket-products > ul {
  height: calc(100vh - 200px);
  list-style: none;
  overflow: auto;
}
.checkOut-basket-products > ul >li {
  padding-bottom: 1em;
  padding-top: 1em;
  width: 260px;
}
@media ( max-width:460px){
  .checkOut-cart-subtotal{
  font-size: 20px;
  color: black;
  text-align: center;
  position: static;
  margin-top: 1em;
  margin-bottom: 1em;
  }
  .products-list-container{
  margin-left:0;
  height: auto;
  position: static;
  }
  .checkOut-basket-products{
    padding: 0;
  }
}
.container {
  padding-right: 0 !important;
}
.margin-b {
  margin-bottom: 20px;
  /* LA CONCHA DE TU MADRE BOOTSTRAP */
}
.metodo-pago-container {
  padding-top: 1.5em;
  display: grid;
  width: 100%;
  grid-template-columns: 75% 25%;
}
.metodo-pago-container label {
  margin: auto;
}
.input-radio {
  display: inline-block
}
.input-radio__body {
  display: block;
  position: relative;
  width: 16px;
  height: 16px
}
.input-radio__input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: inherit
}
.input-radio__circle {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .17);
  transition: background .2s, box-shadow .2s
}
.input-radio__circle:after {
  display: block;
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: #fff;
  transform: scale(0);
  transition: background .2s, transform .2s, visibility 0s .2s;
  visibility: hidden
}
.input-radio__input:checked~.input-radio__circle {
  background: var(--primary-color);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .08)
}
.input-radio__input:checked~.input-radio__circle:after {
  background: #fff;
  visibility: visible;
  transition-delay: 0s, 0s, 0s;
  transform: scale(1)
}
.input-radio__input:disabled~.input-radio__circle {
  background: #f2f2f2;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .12)
}
.input-radio__input:disabled~.input-radio__circle:after {
  background: rgba(0, 0, 0, .2)
}
</style>
